import {users,emails,emoji_star,emoji_timer } from '../../statics'
function Section8() {

    return (
        <section className="container" id="achievements">
            <div className="Stats_stats__6WJOh" style={{backgroundColor: '#212121'}}>
                <h2 className="Stats_stats__title__S1aVf" style={{color: '#fff'}}>Interesting facts</h2>
                <p className="Stats_stats__promotion__r1F7q">We believe that Typely has tremendous potential, and we are
                    determined to unlock this potential and make it a leader in its industry.</p>
                <div className="Stats_stats__grid___5bix">
                    <div className="Stats_stats_card__1EWkx Stats__show__YapyH" data-aos="fade-down" >
                        <div className="Stats_stats_card__img__6GVex">
                            <img alt="users icon" loading="lazy" decoding="async"
                                 data-nimg="fill"
                                 style= {{
                                     position: 'absolute',
                                     height: '100%',
                                     width: '100%',
                                     left: 0,
                                     top: 0,
                                     right: 0,
                                     bottom: 0,
                                     color: 'transparent',
                                 }}                             src={users}/>
                        </div>
                        <div className="Stats_stats_card__text__jBhvQ">
                            <p>3M+</p>
                            <span>active users</span>
                        </div>
                    </div>
                    <div className="Stats_stats_card__1EWkx Stats__show__YapyH" data-aos="fade-down" data-aos-delay="200">
                        <div className="Stats_stats_card__img__6GVex">
                            <img alt="emails icon" loading="lazy" decoding="async"
                                 data-nimg="fill"
                                 style= {{
                                     position: 'absolute',
                                     height: '100%',
                                     width: '100%',
                                     left: 0,
                                     top: 0,
                                     right: 0,
                                     bottom: 0,
                                     color: 'transparent',
                                 }}                                   src={emails}/>
                        </div>
                        <div className="Stats_stats_card__text__jBhvQ">
                            <p>30M+</p>
                            <span>generated e-mails</span>
                        </div>
                    </div>
                    <div className="Stats_stats_card__1EWkx Stats__show__YapyH" data-aos="fade-down"data-aos-delay="400">
                        <div className="Stats_stats_card__img__6GVex">
                            <img alt="emails icon" loading="lazy" decoding="async"
                                 data-nimg="fill"
                                 style= {{
                                     position: 'absolute',
                                     height: '100%',
                                     width: '100%',
                                     left: 0,
                                     top: 0,
                                     right: 0,
                                     bottom: 0,
                                     color: 'transparent',
                                 }}                                   src={emoji_star}/>
                        </div>
                        <div className="Stats_stats_card__text__jBhvQ">
                            <p>4.9/5</p>
                            <span>overall rating</span>
                        </div>
                    </div>
                    <div className="Stats_stats_card__1EWkx Stats__show__YapyH" data-aos="fade-down" data-aos-delay="600">
                        <div className="Stats_stats_card__img__6GVex"><
                            img alt="emails icon" loading="lazy" decoding="async"
                                data-nimg="fill"
                                style= {{
                                    position: 'absolute',
                                    height: '100%',
                                    width: '100%',
                                    left: 0,
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    color: 'transparent',
                                }}                                  src={emoji_timer}/>
                        </div>
                        <div className="Stats_stats_card__text__jBhvQ">
                            <p>4 sec</p>
                            <span>avg. response time</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
);
}

export default Section8;
