function Badge() {

    return (

        <div className={"container"} style={{
            width: '100%',
            marginTop: '10px',
            // marginBottom: '10px ',
            display: "flex",
            justifyContent: 'center',
            alignItems:'center'


            // all: 'unset' // Note: The 'all' property might not be fully supported in inline styles, use with caution.
        }}>

            <div id="brandpush-trust-badge"
                 style={{
                     position: 'relative',
                     width: '100%',
                     background: '#ffffff',
                     borderRadius: '50px',
                     minHeight: '180px',
                     marginLeft: 'auto',
                     marginRight: 'auto',
                     paddingBottom: '12px'
                 }}>
                <div
                    style={{
                        textAlign: 'center',
                        padding: '0px 5px 10px 5px',
                        fontSize: '18px',
                        fontFamily: 'sans-serif',
                        fontWeight: 600,
                        letterSpacing: '8px',
                        lineHeight: 1.3
                    }}>
                    <div style={{height: "58px"}}>
                        <span className="brandpush-title"
                              style={{
                                  zIndex: "1",
                                  position: "relative",
                                  padding: "0 20px",
                                  margin: "0",
                                  color: "#0e0e0e"
                              }}>AS SEEN ON</span>
                    </div>
                    <div className="brandpush-logo-container">
                        <img className="brandpush-leaf" alt="Trust Reef"
                             style={{
                                 position: "absolute",
                                 height: "110px",
                                 left: "25px",
                                 margin: "0",
                                 padding: "0",
                                 zIndex: "0",
                                 opacity: "0.2"
                             }}
                             src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/3fb10293-8878-4ce5-5496-cef376fe9300/public"/>
                        <img
                            className="brandpush-leaf" alt="Trust Reef"
                            style={{
                                position: 'absolute',
                                height: '110px',
                                right: '25px',
                                margin: 0,
                                padding: 0,
                                zIndex: 0,
                                opacity: 0.2,
                                WebkitTransform: 'scaleX(-1)', // Note the capitalization for vendor prefix in React
                                transform: 'scaleX(-1)'
                            }}
                            src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/3fb10293-8878-4ce5-5496-cef376fe9300/public"/>
                        <div className="brandpush-logo-container-item">
                            <div className="brandpush-vertical-center">
                                <a href="https://www.benzinga.com/pressreleases/24/04/ab38471205/revolutionizing-business-communications-with-typely-the-ai-powered-communication-tool"
                                   target="_Blank" className="brandpush-newslogos" rel="noreferrer">
                                    <img alt="Featured on Benzinga"
                                         className="brandpush-news-logo"
                                         style={{marginRight: "14px"}}
                                         src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/ec881fff-1d06-43da-18e1-4460b8922c00/public"/></a>
                                <a href="https://www.barchart.com/story/news/25777266/revolutionizing-business-communications-with-typely-the-aipowered-communication-tool"
                                   target="_Blank" className="brandpush-newslogos" rel="noreferrer">
                                    <img alt="Featured on BarChart"
                                         className="brandpush-news-logo"
                                         style={{marginRight: "14px"}}
                                         src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/d1d24201-6f48-4c9b-6ed2-46464eac1900/public"/>
                                </a>
                                <a href="https://www.theglobeandmail.com/investing/markets/markets-news/GetNews/25777303/revolutionizing-business-communications-with-typely-the-aipowered-communication-tool"
                                   target="_Blank" className="brandpush-newslogos" rel="noreferrer">
                                    <img alt="Featured on The Globe And Mail" className="brandpush-news-logo"
                                         style={{marginRight: "14px"}}
                                         src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/7acfc7a6-2b16-4ba6-01d5-96b99d462f00/public"/>
                                </a>
                                <a href="https://www.wicz.com/story/50719086/revolutionizing-business-communications-with-typely-the-aipowered-communication-tool"
                                   target="_Blank" className="brandpush-newslogos" rel="noreferrer">
                                    <img alt="Featured on FOX 40" className="brandpush-news-logo"
                                         style={{marginRight: "14px"}}
                                         src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/3fc4af63-e906-4112-d141-d504c6ea5c00/public"/>
                                </a>
                                <a href="https://www.newschannelnebraska.com/story/50719086/revolutionizing-business-communications-with-typely-the-aipowered-communication-tool"
                                   target="_Blank" className="brandpush-newslogos" rel="noreferrer">
                                    <img alt="Featured on NCN" className="brandpush-news-logo"
                                         style={{marginRight: "14px"}}
                                         src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/31f90197-ad2e-4772-b420-56e60b9b4a00/public"/>
                                </a>
                                <a href="http://finance.minyanville.com/minyanville/article/getnews-2024-4-26-revolutionizing-business-communications-with-typely-the-ai-powered-communication-tool"
                                   target="_Blank" className="brandpush-newslogos" rel="noreferrer">
                                    <img alt="Featured on Minyanville" className="brandpush-news-logo"
                                         style={{marginRight: "14px"}}
                                         src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/b79268ce-3bb2-42ee-7919-7c650b840700/public"/>
                                </a>
                                <a href="http://business.starkvilledailynews.com/starkvilledailynews/markets/article/getnews-2024-4-26-revolutionizing-business-communications-with-typely-the-ai-powered-communication-tool/"
                                   target="_Blank" className="brandpush-newslogos" rel="noreferrer">
                                    <img alt="Featured on Starkville Daily News" className="brandpush-news-logo"
                                         style={{marginRight: "14px"}}
                                         src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/5c25786a-6267-4a1d-bcb7-3ccaa5676700/public"/>
                                </a>
                                <a href="http://markets.chroniclejournal.com/chroniclejournal/article/getnews-2024-4-26-revolutionizing-business-communications-with-typely-the-ai-powered-communication-tool/"
                                   target="_Blank" className="brandpush-newslogos" rel="noreferrer">
                                    <img alt="Featured on The Chronicle Journal" className="brandpush-news-logo"
                                         style={{marginRight: "14px"}}
                                         src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/ac708810-3bf8-4cd0-f934-a3f51cd64e00/public"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: "0px", fontFamily: "sans-serif !important"}}>
                        <span className="brandpush-footer"
                              style={{
                                  textAlign: 'center',
                                  padding: '0 20px',
                                  fontSize: '13px',
                                  fontFamily: 'sans-serif',
                                  fontWeight: 600,
                                  letterSpacing: '3px',
                                  position: 'relative',
                                  width: '100%',
                                  margin: '0 0 8px 0',
                                  display: 'inline-block',
                                  color: '#a5a5a5'  // Fixed color code syntax
                              }}>AND OVER 400 NEWS SITES</span>
                        <div style={{
                            color: '#717171',
                            fontSize: '10px',
                            letterSpacing: '0',
                            height: '15px',
                            margin: '3px 0 0 0',
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center'
                        }}>"
                            <img style={{width: "12px", margin: "0 3px 0 0"}}
                                 src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/5e5b9b47-6288-4640-2b2a-cd2459605c00/public"
                                 alt={""}/>
                            <span style={{fontFamily: " sans-serif !important;"}}>
                            Verified by
                            <a style={{color: "#717171"}}
                               href="https://www.brandpush.co?utm_source=Client+Referral&utm_medium=Trust+Badge&utm_campaign=Trust+Badge&utm_content=1713474897541"
                               target="_Blank" rel="noreferrer">BrandPush.co</a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Badge;
