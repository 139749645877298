import {google, star, apple, star_alt} from '../../statics'

function Section5() {


    return (
        <section className="container" id="reviews">
            <h1 className="Reviews_reviews__title__8tc3j" style={{color: '#fff'}}>Reviews</h1>
            <p className="Reviews_reviews__promotion__4wg_T">If you're curious about Typely's performance, you'll be
                pleased to know
                that it has garnered rave reviews from thousands of satisfied customers all over the globe:</p>
            <div className="Reviews_reviews__grid__XVK1_">
                <div className="Reviews_reviews_item__9oEmh Reviews_light__4vlFQ   " data-aos="fade-up" style={{backgroundColor: '#212121'}}>
                    <img alt="apple logo"
                         loading="lazy" width="24" height="24" decoding="async" data-nimg="1"
                         className="Reviews_reviews_item__icon__ymDwE" src={apple} style={{color: 'transparent'}}/>
                    <p className="Reviews_reviews_item__title__9HJlE" style={{color: '#fff'}}>Typely — the premier AI Email Assistant available
                        today</p>
                    <p className="Reviews_reviews_item__text__dlYFG">For several months, I've been utilizing TYPELY and
                        I must say it's among the top AI email assistants available. It has revolutionized my approach
                        to managing emails, enabling me to swiftly locate pertinent messages and significantly reducing
                        the time I spend on my inbox.
                    </p>
                    <div className="Reviews_reviews_item__footer__JQo_F">
                        <p className="Reviews_reviews_item__name___JRDx" style={{color: '#fff'}}>Samantha Reed</p>
                        <div className="Reviews_reviews_item__rating__ZIaJu">
                            <img alt="star icon" loading="lazy"
                                 width="18"
                                 height="18" decoding="async"
                                 data-nimg="1" src={star}
                                 style={{color: 'transparent'}}/>5.0
                        </div>
                    </div>
                </div>
                <div className="Reviews_reviews_item__9oEmh Reviews_dark__iiEPu   " data-aos="fade-up">
                    <img alt="apple logo"
                         loading="lazy" width="24" height="24" decoding="async" data-nimg="1"
                         className="Reviews_reviews_item__icon__ymDwE" src={apple} style={{color: 'transparent'}}/>
                    <p className="Reviews_reviews_item__title__9HJlE">I am truly grateful!
                    </p>
                    <p className="Reviews_reviews_item__text__dlYFG">I'm at a loss for words to fully express how
                        beneficial this app has been to me. My heartfelt thanks go out to the developers of this
                        incredible application.</p>
                    <div className="Reviews_reviews_item__ratingApple__i_9va">
                        <img alt="star icon" loading="lazy"
                             width="18"
                             height="18" decoding="async"
                             data-nimg="1" src={star}
                             style={{color: 'transparent'}}/>
                        <img
                            alt="star icon" loading="lazy" width="18" height="18" decoding="async" data-nimg="1"
                            src={star}
                            style={{color: 'transparent'}}/>
                        <img alt="star icon" loading="lazy" width="18" height="18"
                             decoding="async" data-nimg="1" src={star}
                             style={{color: 'transparent'}}/>
                        <img alt="star icon"
                             loading="lazy" width="18"
                             height="18" decoding="async"
                             data-nimg="1"
                             src={star}
                             style={{color: 'transparent'}}/>
                        <img
                            alt="star icon" loading="lazy" width="18" height="18"
                            decoding="async" data-nimg="1" src={star} style={{color: 'transparent'}}/>
                    </div>
                </div>
                <div className="Reviews_reviews_sign__CFIW5 Reviews__rating___tUeP   " data-aos="fade-up">
                    <img
                        alt="star icon"
                        loading="lazy" width="28" height="28" decoding="async" data-nimg="1" src={star_alt}
                        style={{color: 'transparent'}}/>
                    <img alt="star icon" loading="lazy" width="28" height="28"
                         decoding="async"
                         data-nimg="1" src={star_alt} style={{color: 'transparent'}}/>
                    <img
                        alt="star icon" loading="lazy"
                        width="18" height="18" decoding="async" data-nimg="1" src={star_alt}
                        style={{color: 'transparent'}}/>
                    <div className="Reviews_reviews_sign__container__iGp9m Reviews__rating___tUeP">Average
                        Rating:
                        4.9/5
                    </div>
                </div>
                <div className="Reviews_reviews_item__9oEmh Reviews_light__4vlFQ   " data-aos="fade-up" style={{backgroundColor: '#212121'}}>
                    <img
                        alt="apple logo"
                        loading="lazy" width="24" height="24" decoding="async" data-nimg="1"
                        className="Reviews_reviews_item__icon__ymDwE" src={apple} style={{color: 'transparent'}}/>
                    <p className="Reviews_reviews_item__title__9HJlE"style={{color: '#fff'}} >The voice command functionality is exceptional!
                    </p>
                    <p className="Reviews_reviews_item__text__dlYFG">This AI email assistant is the most user-friendly
                        I've ever encountered, offering significant time savings. The auto-complete feature is a dream
                        as I draft emails, and its ability to accurately interpret my spoken instructions is truly
                        remarkable!</p>
                    <div className="Reviews_reviews_item__ratingApple__i_9va">
                        <img alt="star icon" loading="lazy"
                             width="18"
                             height="18" decoding="async"
                             data-nimg="1" src={star}
                             style={{color: 'transparent'}}/>
                        <img
                            alt="star icon" loading="lazy" width="18" height="18" decoding="async" data-nimg="1"
                            src={star}
                            style={{color: 'transparent'}}/>
                        <img alt="star icon" loading="lazy" width="18" height="18"
                             decoding="async" data-nimg="1" src={star}
                             style={{color: 'transparent'}}/>
                        <img alt="star icon"
                             loading="lazy" width="18"
                             height="18" decoding="async"
                             data-nimg="1" src={star}
                             style={{color: 'transparent'}}/>
                        <img
                            alt="star icon" loading="lazy" width="18" height="18"
                            decoding="async" data-nimg="1" src={star} style={{color: 'transparent'}}/>
                    </div>
                </div>
                <div className="Reviews_reviews_item__9oEmh Reviews_light__4vlFQ   " data-aos="fade-up" style={{backgroundColor: '#212121'}}>
                    <img
                        alt="apple logo"
                        loading="lazy" width="24" height="24" decoding="async" data-nimg="1"
                        className="Reviews_reviews_item__icon__ymDwE" src={apple} style={{color: 'transparent'}}/>
                    <p className="Reviews_reviews_item__title__9HJlE" style={{color: '#fff'}}>Fantastic Application!
                    </p>
                    <p className="Reviews_reviews_item__text__dlYFG">I'm thoroughly impressed by this application; it's
                        incredibly useful. The only caveat is the price point. It would be beneficial to have varied
                        subscription options, perhaps on a weekly or monthly basis. All the best!</p>
                    <div className="Reviews_reviews_item__footer__JQo_F">
                        <p className="Reviews_reviews_item__name___JRDx" style={{color: '#fff'}}>John Shippy</p>
                        <div className="Reviews_reviews_item__rating__ZIaJu">
                            <img alt="star icon" loading="lazy"
                                 width="18"
                                 height="18" decoding="async"
                                 data-nimg="1" src={star}
                                 style={{color: 'transparent'}}/>5.0
                        </div>
                    </div>
                </div>
                <div className="Reviews_reviews_item__9oEmh Reviews_dark__iiEPu   " data-aos="fade-up">
                    <img alt="apple logo"
                         loading="lazy" width="24" height="24" decoding="async" data-nimg="1"
                         className="Reviews_reviews_item__icon__ymDwE" src={apple} style={{color: 'transparent'}}/>
                    <p className="Reviews_reviews_item__title__9HJlE">Typely completely revolutionized my routine!

                    </p>
                    <p className="Reviews_reviews_item__text__dlYFG">Typely has utterly transformed the way I work. As
                        an AI email assistant, it has an intuitive understanding of my requirements, keeping me
                        well-organized and on track.


                    </p>
                    <div className="Reviews_reviews_item__footer__JQo_F">
                        <p className="Reviews_reviews_item__name___JRDx">Alice Broadhurst</p>
                        <div className="Reviews_reviews_item__rating__ZIaJu">
                            <img alt="star icon" loading="lazy"
                                 width="18"
                                 height="18" decoding="async"
                                 data-nimg="1" src={star}
                                 style={{color: 'transparent'}}/>5.0
                        </div>
                    </div>
                </div>
                <div className="Reviews_reviews_sign__CFIW5 Reviews__users__KWUXm   " data-aos="fade-up">
                    <img
                        alt="star icon"
                        loading="lazy" width="28" height="28" decoding="async" data-nimg="1" src={star_alt}
                        style={{color: 'transparent'}}/>
                    <img alt="star icon" loading="lazy" width="28" height="28"
                         decoding="async"
                         data-nimg="1" src={star_alt}
                         style={{color: 'transparent'}}/>
                    <img
                        alt="star icon" loading="lazy"
                        width="18" height="18" decoding="async" data-nimg="1" src={star_alt}
                        style={{color: 'transparent'}}/>
                    <div className="Reviews_reviews_sign__container__iGp9m Reviews__users__KWUXm">More then 1 million
                        users
                    </div>
                </div>
                <div className="Reviews_reviews_item__9oEmh Reviews_light__4vlFQ   " data-aos="fade-up" style={{backgroundColor: '#212121'}}><img
                    alt="apple logo" loading="lazy" width="24" height="24" decoding="async" data-nimg="1"
                    className="Reviews_reviews_item__icon__ymDwE" src={apple} style={{color: 'transparent'}}/><p
                    className="Reviews_reviews_item__title__9HJlE" style={{color: '#fff'}}>Easy available personal email assistance.</p><p
                    className="Reviews_reviews_item__text__dlYFG">I never have to worry about forgetting to reply to an
                    important email or missing tasks due to its smart reminders. It's like having a personal assistant
                    at my fingertips.</p>
                    <div className="Reviews_reviews_item__ratingApple__i_9va">
                        <img alt="star icon" loading="lazy"
                             width="18" height="18"
                             decoding="async" data-nimg="1"
                             src={star}
                             style={{color: 'transparent'}}/>
                        <img
                            alt="star icon" loading="lazy" width="18" height="18" decoding="async" data-nimg="1"
                            src={star} style={{color: 'transparent'}}/>
                        <img alt="star icon" loading="lazy" width="18"
                             height="18" decoding="async" data-nimg="1"
                             src={star}
                             style={{color: 'transparent'}}/>
                        <img
                            alt="star icon" loading="lazy" width="18" height="18" decoding="async" data-nimg="1"
                            src={star} style={{color: 'transparent'}}/>
                        <img alt="star icon" loading="lazy" width="18"
                             height="18" decoding="async" data-nimg="1"
                             src={star} style={{color: 'transparent'}}/>
                    </div>
                </div>
                <div className="Reviews_reviews_item__9oEmh Reviews_light__4vlFQ   " data-aos="fade-up" style={{backgroundColor: '#212121'}}>
                    <img
                        alt="apple logo"
                        loading="lazy" width="24" height="24" decoding="async" data-nimg="1"
                        className="Reviews_reviews_item__icon__ymDwE" src={apple} style={{color: 'transparent'}}/>
                    <p className="Reviews_reviews_item__title__9HJlE" style={{color: '#fff'}}>Effortless personal email assistance at your
                        disposal.
                    </p>
                    <p className="Reviews_reviews_item__text__dlYFG">Forgetting to respond to a crucial email or
                        overlooking tasks is no longer a concern. It feels as though I have a personal assistant right
                        at my fingertips.</p>
                    <div className="Reviews_reviews_item__ratingApple__i_9va">
                        <img alt="star icon" loading="lazy"
                             width="18"
                             height="18" decoding="async"
                             data-nimg="1" src={star}
                             style={{color: 'transparent'}}/>
                        <img
                            alt="star icon" loading="lazy" width="18" height="18" decoding="async" data-nimg="1"
                            src={star}
                            style={{color: 'transparent'}}/>
                        <img alt="star icon" loading="lazy" width="18" height="18"
                             decoding="async" data-nimg="1" src={star}
                             style={{color: 'transparent'}}/>
                        <img alt="star icon"
                             loading="lazy" width="18"
                             height="18" decoding="async"
                             data-nimg="1" src={star}
                             style={{color: 'transparent'}}/>
                        <img
                            alt="star icon" loading="lazy" width="18" height="18"
                            decoding="async" data-nimg="1" src={star} style={{color: 'transparent'}}/>
                    </div>
                </div>
                <div className="Reviews_reviews_item__9oEmh Reviews_dark__iiEPu   " data-aos="fade-up">
                    <img alt="apple logo"
                         loading="lazy" width="24" height="24" decoding="async" data-nimg="1"
                         className="Reviews_reviews_item__icon__ymDwE" src={apple} style={{color: 'transparent'}}/>
                    <p className="Reviews_reviews_item__title__9HJlE">Intuitive interface</p>
                    <p className="Reviews_reviews_item__text__dlYFG">It's the best AI email assistant I've ever used,
                        and it helps
                        me quickly and easily stay on top of my inbox. The user interface is so intuitive that I don't
                        even need
                        to read the instructions.</p>
                    <div className="Reviews_reviews_item__footer__JQo_F">
                        <p className="Reviews_reviews_item__name___JRDx">Jeffrey Reis</p>
                        <div className="Reviews_reviews_item__rating__ZIaJu">
                            <img alt="star icon" loading="lazy"
                                 width="18"
                                 height="18" decoding="async"
                                 data-nimg="1" src={star}
                                 style={{color: 'transparent'}}/>5.0
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Section5;
