import 'aos/dist/aos.css';
import {download_2, download_3, app_store, download_1_png} from '../../statics'

function Section7() {

    return (
        <section className="Download_download__pbPaD container" id="mobile-app"><h2
            className="Download_download__title__tQlAz" style={{color: '#fff'}}>Available on iOS</h2>
            <p className="Download_download__promotion__s1Pz_">The ultimate mobile app was created for anyone looking to
                improve their writing skills.<br/>Download Typely today and start writing like a Pro!</p>
            <div className="Download_download__container__zo9Ag">
                <div>
                    <img alt="App previews"
                         // fetchPriority="high"
                         // decoding="async"
                         // data-nimg="fill"
                         sizes="100vw"
                         src={download_1_png}
                         data-aos="zoom-in"

                         style={{
                             position: 'absolute',
                             height: '100%',
                             width: '100%',
                             inset: '0px',
                             transform: 'translate(-50%) scale(1)'
                         }}/>
                    <img alt="App previews"

                         src={download_2}
                         data-aos="zoom-in"
                         data-aos-delay="300"
                         style={{
                             position: 'absolute',
                             height: '100%',
                             width: '100%',
                             inset: ' 0px',
                             color: 'transparent',
                             transform: 'translate(-50%) scale(.75) translate(-75%) rotate(-8.5deg)'

                         }}/>
                    <img
                        alt="App previews"
                        src={download_3}
                        data-aos="zoom-in"
                        data-aos-delay="600"
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            inset: ' 0px',
                            color: 'transparent',
                            transform: 'translate(-50%) scale(0.75) translate(75%) rotate(8.5deg)'
                        }}/>
                </div>
            </div>
            <div className="Download_download__buttons__wHpyj">
                <div id="dwnld-btn-apple" className="Download_download__button__F9Ceh">
                    <a href={"https://apps.apple.com/ae/app/typely-ai-email-writer/id6450997696"}>
                        <img alt="App store"
                             loading="lazy"
                             width="181" height="60"
                             decoding="async"
                             data-nimg="1"
                             src={app_store}
                             style={{color: 'transparent'}}/>
                        </a>
                </div>
                {/*<div id="dwnld-btn-google" className="Download_download__button__F9Ceh">*/}
                    {/*<img alt="App store"*/}
                    {/*     loading="lazy"*/}
                    {/*     width="204" height="60"*/}
                    {/*     decoding="async"*/}
                    {/*     data-nimg="1"*/}
                    {/*     src={play_store}*/}
                    {/*     style={{color: 'transparent'}}/>*/}
                {/*</div>*/}
            </div>
        </section>
    );
}

export default Section7;
