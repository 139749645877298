import React, { useState, useEffect } from 'react';
import {app_store} from "../../statics";

const WordAnimation = ({ words, writeSpeed }) => {
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [charIndex, setCharIndex] = useState(0);
    const [isAdding, setIsAdding] = useState(true);
    const [isWaiting, setIsWaiting] = useState(false);

    useEffect(() => {
        // Stop animation if there are no words
        if (words.length === 0 || isWaiting) return;

        const currentWord = words[currentWordIndex];
        let actionDelay = writeSpeed; // Default speed is for writing

        if (!isAdding) {
            actionDelay /= 2; // Removing speed is twice as fast
        }

        if (isAdding && charIndex === currentWord.length) {
            // Wait for 1 second after writing before starting to remove
            setIsWaiting(true);
            setTimeout(() => {
                setIsWaiting(false);
                setIsAdding(false);
            }, 1000); // 1 second delay
            return;
        } else if (!isAdding && charIndex === 0) {
            // Switch to the next word after removing
            setIsAdding(true);
            setCurrentWordIndex((currentWordIndex + 1) % words.length);
        }

        // Schedule next update
        const timeoutId = setTimeout(() => {
            setCharIndex(isAdding ? charIndex + 1 : charIndex - 1);
        }, actionDelay);

        // Cleanup timeout on component unmount or before executing the next effect
        return () => clearTimeout(timeoutId);
    }, [charIndex, isAdding, currentWordIndex, words, writeSpeed, isWaiting]);

    const displayedText = words[currentWordIndex]?.substring(0, charIndex) || '';

    return <span className="Writing_writing_title__write__7VnMB">{displayedText}</span>;
};

function Section1() {
    const words = ["cover", "Write famous quote", "resume", "interview"];
    const writeSpeed = 200; // Typing speed in milliseconds for writing

    return (
        <section className="container Writing_writing__0L5xK" id="writing">
            <h2 className="Writing_writing_title__fExOI" style={{color: "#fff"}}>Typely is best for writing
                <br/>
                <WordAnimation words={words} writeSpeed={writeSpeed}/>
                <span className="Writing_writing_title__cursor__guZLP" style={{animationDuration: '1s'}}></span>
            </h2>
            <p className="Writing_writing__promotion__XwIbG">Experience effortless email communication with the help of
                AI-powered writing assistance.</p>
            {/*<button className="uiButton" id="writing-btn">Start Free</button>*/}

            <div className="Download_download__buttons__wHpyj">
                <div id="dwnld-btn-apple" className="Download_download__button__F9Ceh">
                    <a href={"https://apps.apple.com/ae/app/typely-ai-email-writer/id6450997696"}>
                    <img alt="App store"
                         loading="lazy"
                         width="181" height="60"
                         decoding="async"
                         data-nimg="1"
                         src={app_store}
                         style={{color: 'transparent'}}/></a>
                </div>
                {/*<div id="dwnld-btn-google" className="Download_download__button__F9Ceh">*/}
                {/*<img alt="App store"*/}
                {/*     loading="lazy"*/}
                {/*     width="204" height="60"*/}
                {/*     decoding="async"*/}
                {/*     data-nimg="1"*/}
                {/*     src={play_store}*/}
                {/*     style={{color: 'transparent'}}/>*/}
                {/*</div>*/}
            </div>
            {/*<span className="Writing_writing__note__Uh1uD">No credit card required</span>*/}
        </section>
    );
}

export default Section1;
