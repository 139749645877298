import {cover_letter,day_off_request,arrange_meeting,being_late_for_work,resignation_letter,pay_increase,summarize_document,write_famous_quote,generate_strong_password,rephrasing} from '../../statics'
function Section4() {
    return (

        <section className="container" id="features">
            <div className="Features_features__U_tfa" style={{backgroundColor: '#212121'}}>
                <h2 className="Features_features__title__Grv_D" style={{color: '#fff'}}>What can Typely do for you?</h2>
                <p className="Features_features__promotion__tJECU">Whether you want to write to your partner, friend, or colleague,
                    get advice, or find new connections, Typely has it covered. Whether you're a student, a professional, or
                    someone who wants to improve their writing, Typely is here to help.</p>
                <div className="Features_features_ticker__1t83d">
                    <div className="Features_features_ticker__container__QcHBK">
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Cover letter icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={cover_letter} />Cover letter</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Day off request icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={day_off_request}/>Day off request</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Arrange meeting icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={arrange_meeting}/>Arrange meeting</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Being late for work icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={being_late_for_work}/>Being late for work</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Resignation letter icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={resignation_letter}/>Resignation letter</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Pay increase icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={pay_increase}/>Pay increase</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Summarize document icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={summarize_document}/>Summarize document</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Write famous quote icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={write_famous_quote}/>Write famous quote</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Generate strong password icon"
                                                                                loading="lazy" width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={generate_strong_password}/>Generate strong password</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Rephrasing icon" loading="lazy" width="24"
                                                                                height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={rephrasing}/>Rephrasing</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Cover letter icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={cover_letter}/>Cover letter</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Day off request icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={day_off_request}/>Day off request</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Arrange meeting icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={arrange_meeting}/>Arrange meeting</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Being late for work icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={being_late_for_work}/>Being late for work</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Resignation letter icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={resignation_letter}/>Resignation letter</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Pay increase icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={pay_increase}/>Pay increase</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Summarize document icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={summarize_document}/>Summarize document</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Write famous quote icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={write_famous_quote}/>Write famous quote</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Generate strong password icon"
                                                                                loading="lazy" width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={generate_strong_password}/>Generate strong password</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Rephrasing icon" loading="lazy" width="24"
                                                                                height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={rephrasing}/>Rephrasing</div>
                    </div>
                    <div className="Features_features_ticker__container__QcHBK">
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Resignation letter icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={resignation_letter}/>Resignation letter</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Summarize document icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={summarize_document}/>Summarize document</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Day off request icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={day_off_request}/>Day off request</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Rephrasing icon" loading="lazy" width="24"
                                                                                height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={rephrasing}/>Rephrasing</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Write famous quote icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={write_famous_quote}/>Write famous quote</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Being late for work icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={being_late_for_work}/>Being late for work</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Cover letter icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={cover_letter}/>Cover letter</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Arrange meeting icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={arrange_meeting}/>Arrange meeting</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Pay increase icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={pay_increase}/>Pay increase</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Generate strong password icon"
                                                                                loading="lazy" width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={generate_strong_password}/>Generate strong password</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Resignation letter icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={resignation_letter}/>Resignation letter</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Summarize document icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={summarize_document}/>Summarize document</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Day off request icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={day_off_request}/>Day off request</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Rephrasing icon" loading="lazy" width="24"
                                                                                height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={rephrasing}/>Rephrasing</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Write famous quote icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={write_famous_quote}/>Write famous quote</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Being late for work icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={being_late_for_work}/>Being late for work</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Cover letter icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={cover_letter}/>Cover letter</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Arrange meeting icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={arrange_meeting}/>Arrange meeting</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Pay increase icon" loading="lazy"
                                                                                width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={arrange_meeting}/>Pay increase</div>
                        <div className="Features_features_ticker__item__2hKn4" style={{color: '#fff'}}><img alt="Generate strong password icon"
                                                                                loading="lazy" width="24" height="24" decoding="async" data-nimg="1" style={{color:'transparent'}}
                                                                                src={generate_strong_password}/>Generate strong password</div>
                    </div>
                </div>
                {/*<button className="uiButton" id="features-btn">Start Free*/}
                {/*<a className="getapp_button"></a>*/}
                {/*</button>*/}
            </div>
        </section>
    );
}

export default Section4;
