import './App.css';
import { Navbar, Section1,Section2,Section3,Section4,Section5,Section6,Section7,Section8 ,Section9,Footer} from './Components';
import {useEffect} from "react"; // Importing from the components directory
import AOS from "aos";

import 'aos/dist/aos.css';
import Badge from "./Components/Badge";
function App() {
    useEffect(() => {
        // Initialize AOS
        AOS.init({
            duration: 1000, // values from 0 to 3000, with step 50ms
            once: true, // whether animation should happen only once - while scrolling down
            mirror: false, // whether elements should animate out while scrolling past them
        });
    }, []);
    useEffect(() => {
        const updateVH = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        // Set the initial value
        updateVH();

        // Add event listener
        window.addEventListener('resize', updateVH);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', updateVH);
    }, []); // Empty array ensures this effect runs only once on mount
  return (
      <div className="main " id="main" style={{backgroundColor: '#171717'}}>
          <Navbar/>
          <Section1/>
          <Badge/>
          {/*<Section2/>*/}
          <Section3/>
          <Section4/>
          <Section5/>
          {/*<Section6/>*/}
          <br/>
          <br/>
          <br/>
          <Section7/>
          <Section8/>
          <Section9/>


          <Footer/>



      </div>
  );
}

export default App;
