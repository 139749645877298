import React, { useRef, useEffect, useState } from 'react';
import {GPT4_viedo} from "../../statics";

function Section3() {
    // const canvasRef = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);




    return (
        <section className="container narrow About_about__FVt3e" id="ai-technology">
            <h2 className="About_about__title__O_k_G" style={{color: '#fff'}}>Powered by advanced<br /> GPT-4 capabilities</h2>
            <p className="About_about__promotion__rm0H9">
                With its powerful AI technology, the app can assist with everything from proofreading and grammar checking to suggestions for improving your writing.
            </p>

                <div className="About_about__img__E4QX9 About__chrome__jScll" id="waves-container">
                    <div className="react-p5" data-testid="react-p5">
                        {/*<canvas ref={canvasRef} id="defaultCanvas0" className="p5Canvas" width="460" height="168" style={{ width: '460px', height: '168px' }}></canvas>*/}
                        <video  autoPlay loop muted  style={ {
                            width: '100%',     // Makes the video take the full width of its container
                            height: '100%',    // Makes the video take the full height of its container
                        }}>
                            <source    src={GPT4_viedo} type="video/mp4"/>
                        </video>
                    </div>
                </div>
        </section>
    );
}

export default Section3;
