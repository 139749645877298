
function Section9() {

    return (
        <div className="container " id="narrow" >
            <div className="Ready_ready__ZTJsM" style={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                // Full viewport height, adjust as necessary
            }}>
                <div><h2 className="Ready_ready__title__udxH9" style={{color: '#fff'}}>Ready to try Typely?</h2><p
                    className="Ready_ready__promotion__6uSLJ">Say goodbye to the hassle of email communication and hello
                    to the future of personalized and streamlined email writing with our AI E-mail assistant.</p>

                        <a style={{
                            display: 'flex',
                            justifyContent: 'center',
                            textAlign: 'center',
                            // Full viewport height, adjust as necessary
                        }}
                            href={"https://apps.apple.com/ae/app/typely-ai-email-writer/id6450997696"}>
                            <div className="uiButton" id="ready-btn" style={{color: '#fff',}}>Download Now </div>
                        </a>

                </div>
                {/*<div className="Ready_ready__logo__0EYlK"></div>*/}
            </div>
        </div>
    );
}

export default Section9;
