import {friday_light, PP, TS} from '../../statics'

function Footer() {

    return (
        <div className="Footer_footer__oaUOW">
            <div className="Footer_footer__top__0_M5y">
                <img alt="Typely logo" loading="lazy" width="125" height="24"
                     decoding="async" data-nimg="1"
                     style={{color: 'transparent', cursor: 'pointer'}}
                     src={friday_light}/>
                <div className="Footer_footer_nav__ahB5s">
                    <span>
                    <a rel="noreferrer" id="footer-pp-link" target="_blank" href={PP}>Privacy Policy</a>
                    <span
                        style={{margin: '0 12px0 12px'}}>|
                    </span>
                        <a id="footer-tou-link" target="_blank" rel="noreferrer"
                           href={TS}>Terms Of Service</a>

                    </span>
                </div>
            </div>
            <div className="Footer_footer_bottom__Y7x4f">
                <div className="Footer_footer_bottom__left__yiS0g">
                    <span>Typely: AI E-mail Assistance</span>
                    <span>All Rights Reserved 2024.</span>
                </div>
            </div>
        </div>
    );
}

export default Footer;
