import React, { useState, useEffect } from 'react';
import { friday } from '../../statics';

const scrollToSection = (sectionId, offset = 0) => {
    const section = document.getElementById(sectionId);
    if (section) {
        const sectionPosition = section.offsetTop - offset;
        window.scrollTo({
            top: sectionPosition,
            behavior: "smooth",
        });
    }
};

function Navbar() {
    const [activeSection, setActiveSection] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [scrolledDown, setScrolledDown] = useState(false);
    const [burgerOpen, setBurgerOpen] = useState(false); // State to manage burger icon toggle

    useEffect(() => {
        const sectionIds = ['ai-technology', 'features', 'reviews', 'demo', 'mobile-app', 'achievements', 'writing', 'narrow'];

        const observer = new IntersectionObserver((entries) => {
            const intersectingEntries = entries.filter(entry => entry.isIntersecting);
            if (intersectingEntries.length > 0) {
                const lastIntersectingEntry = intersectingEntries[intersectingEntries.length - 1];
                setActiveSection(lastIntersectingEntry.target.id);
            }
        }, { threshold: 0.5 });

        sectionIds.forEach(id => {
            const section = document.getElementById(id);
            if (section) observer.observe(section);
        });

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        const handleScroll = () => {
            setScrolledDown(window.scrollY > 100);
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        return () => {
            observer.disconnect();
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleBurger = () => {
        setBurgerOpen(!burgerOpen); // Toggle the burger icon state and menu visibility
    };

    const getInlineStyles = (sectionId) => {
        return {
            color: activeSection === sectionId ? "#00c17e" : "",
            cursor: 'pointer',
        };
    };

    const burgerIconClass = `Header_header_burger__icon____nNs ${burgerOpen ? "Header__opened__RKzXO" : ""}`;
    const menuClass = `Header_header_burger_menu__HS31k ${burgerOpen ? "Header__opened__RKzXO" : ""}`;

    if (isMobile) {
        return (
            <div className="Header_header__container__Hvhsl" style={{backgroundColor: '#212121' ,color:'#fff',fontWeight:'bold'}}>
                <header className="Header_header__R_kkv">
                    <img alt="Friday" src={friday} loading="lazy" width="125" height="24" style={{ cursor: 'pointer', color: 'transparent' }} />
                    {/*<button className={`uiButton Header_header_burger__button__ujRRJ ${scrolledDown ? "Header__opened__RKzXO" : ""}`}>Start Free</button>*/}
                    <div className="Header_header_burger__hvvn2" onClick={toggleBurger}>
                        <div className={burgerIconClass} >
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </header>
                <div className={menuClass}>
                    <nav className="Header_header_burger_menu_nav__UJvo6">
                        <div className="Header_header_burger_menu_nav__item__5vpIk" style={{transitionDelay: '0.2s'}} onClick={() => scrollToSection('ai-technology', 120)}>
                            <div>Technology</div>
                        </div>
                        {/*<div className="Header_header_burger_menu_nav__item__5vpIk" style={{transitionDelay: '0.25s'}}  onClick={() => scrollToSection('features', 120)}>*/}
                        {/*    <div>All-round solution</div>*/}
                        {/*</div>*/}
                        <div className="Header_header_burger_menu_nav__item__5vpIk" style={{transitionDelay: '0.3s'}} onClick={() => scrollToSection('reviews', 120)}>
                            <div>Reviews</div>
                        </div>
                        {/*<div className="Header_header_burger_menu_nav__item__5vpIk" style={{transitionDelay: '0.35s'}}  onClick={() => scrollToSection('demo', 120)}>*/}
                        {/*    <div>Features</div>*/}
                        {/*</div>*/}
                        <div className="Header_header_burger_menu_nav__item__5vpIk" style={{transitionDelay: '0.4s'}} onClick={() => scrollToSection('mobile-app', 120)}>
                            <div>Operating Systems</div>
                        </div>
                        <div className="Header_header_burger_menu_nav__item__5vpIk" style={{transitionDelay: '0.45s'}}   onClick={() => scrollToSection('achievements', 125)}>
                            <div>Interesting facts</div>
                        </div>
                    </nav>
                </div>
            </div>
        );
    } else {
        return (
            <div className="Header_header__container__Hvhsl" >
                <header className="Header_header__R_kkv" style={{backgroundColor: '#212121' ,color:'#fff',fontWeight:'bold'}} >
                    <img alt="Friday" src={friday} loading="lazy" width="125" height="24"
                         style={{cursor: 'pointer', color: 'transparent'}}/>
                    <nav className="Header_header_nav__24YF5">
                        <div style={getInlineStyles('ai-technology')}
                             onClick={() => scrollToSection('ai-technology', 120)}>Technology
                        </div>

                        <div style={getInlineStyles('reviews')}
                             onClick={() => scrollToSection('reviews', 120)}>Reviews
                        </div>
                        {/*<div style={getInlineStyles('demo')} onClick={() => scrollToSection('demo', 120)}>Features*/}
                        {/*</div>*/}
                        <div style={getInlineStyles('mobile-app')}
                             onClick={() => scrollToSection('mobile-app', 120)}>Operating Systems
                        </div>
                        <div style={getInlineStyles('achievements')}
                             onClick={() => scrollToSection('achievements', 125)}>Interesting facts
                        </div>
                        <div >
                        </div>
                    </nav>
                    <div className="_alt ctaButton"></div>
                    {/*<button className="uiButton _alt ctaButton" id="header-btn">Get Started</button>*/}
                </header>
            </div>
        );
    }
}

export default Navbar;
